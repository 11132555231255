<template>
  <BaseCard class="relative w-full login-content rounded overflow-hidden !dark:bg-gray-900">
    <div
      class="flex login-img login-img justify-center items-center py-3"
    >
      <img
        src="~/assets/images/logo.png"
        height="60"
      >
    </div>
    <div
      class="my-11 flex justify-center"
    >
      <div v-if="!loggedIn">
        <UButton
          size="xl"
          color="white"
          @click="loginWithGoogle"
        >
          <img
            src="/google_logo.svg"
            height="20"
            class="pr-5"
          >
          Login with Google
        </UButton>
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
definePageMeta({
  layout: 'login',
})

const { loggedIn } = useUserSession()

function loginWithGoogle () {
  window.location.href = '/api/auth/google'
}
</script>

<style lang="scss">
.login-content {
  min-width: 320px;
  max-width: 660px;
  transform: translateY(-50px);
}

.login-img {
  height: 134px;
  background-color: $color-grey-silver--light;
}
</style>
